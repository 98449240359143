jQuery(document).ready(function($) {
    $('#ajax-search').on('keyup', function() {
        var query = $(this).val();
        if (query.length > 2) {  // Trigger after 3 characters
            $('#loading-spinner').show(); // Show loading spinner
            $.ajax({
                url: ajax_search_params.ajax_url,
                method: 'GET',
                data: {
                    action: 'ajax_search',
                    s: query,
                },
                success: function(response) {
                    $('#loading-spinner').hide(); // Hide loading spinner
                    $('#search-results').html(response); // Display search results
                },
            });
        } else {
            $('#loading-spinner').hide(); // Hide spinner if query is empty or too short
            $('#search-results').html(''); // Clear results
        }
    });
});

